/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
  ChargeInput,
  FormSingleSelectInput,
  ForwardingAddressInput,
  MinMaxFloatRangeInput,
  PricingType,
  RentableItemCategoryInput,
  ResidentInfoInput,
  ResidentInput,
} from '$/graphql/generated';
import ACCEPT_RENEWAL_OFFER_MUTATION from '$/graphql/mutations/renewalOffers/acceptRenewalOffer';
import DECLINE_RENEWAL_OFFER_MUTATION from '$/graphql/mutations/renewalOffers/declineRenewalOffer';
import RESET_ALL_RENEWAL_OFFER_MUTATION from '$/graphql/mutations/renewalOffers/resetAllRenewalOffers';
import SUBMIT_NEED_SOMETHING_ELSE_MUTATION from '$/graphql/mutations/renewalOffers/submitNeedSomethingElse';
import UPDATE_MOVE_OUT_INFO_MUTATION from '$/graphql/mutations/renewalOffers/updateMoveOutInfo';
import GET_USER from '$/graphql/queries/user/getUser';
import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import GET_RENEWAL_DATA from '../../queries/renewal/getRenewalData';

export default function useRenewalOfferActions() {
  const [submitNeedSomethingElse, { loading: needSomethingElseLoading }] =
    useMutation(SUBMIT_NEED_SOMETHING_ELSE_MUTATION);

  const needSomethingElse = useCallback(
    async ({
      needs,
      additionalInfo,
      preferredTermId,
    }: {
      needs: string[];
      additionalInfo: string;
      preferredTermId: string | null;
    }) => {
      await submitNeedSomethingElse({
        variables: {
          needs,
          additionalInfo,
          preferredTermId,
        },
      });
    },
    [submitNeedSomethingElse],
  );

  const [declineRenewalOffer, { loading: declineOfferLoading }] = useMutation(
    DECLINE_RENEWAL_OFFER_MUTATION,
  );

  const declineOffer = useCallback(
    async ({
      forwardingAddress,
      moveOutDate,
      reasonForMoveOut,
      noticeToVacateFlow,
    }: {
      forwardingAddress?: ForwardingAddressInput | undefined;
      moveOutDate?: string | undefined;
      reasonForMoveOut: FormSingleSelectInput;
      noticeToVacateFlow: boolean;
    }) => {
      const response = await declineRenewalOffer({
        variables: {
          noticeToVacateFlow,
          forwardingAddress,
          moveOutDate,
          reasonForMoveOut,
        },
      });
      if (response.data?.declineRenewalOffer) {
        const data = response.data.declineRenewalOffer; // eslint-disable-line
        if (
          data.__typename === 'RenewalOfferDecisionError' &&
          'message' in data
        ) {
          const message = data.message as string;
          return message;
        }
      }
      return response;
    },
    [declineRenewalOffer],
  );

  const [updateMoveOutInfo, { loading: updateMoveOutInfoLoading }] =
    useMutation(UPDATE_MOVE_OUT_INFO_MUTATION);

  const moveOutInfoUpdate = useCallback(
    async ({
      forwardingAddress,
      noticeToVacateFlow,
      reasonForMoveOut,
      moveOutDate,
    }: {
      forwardingAddress?: ForwardingAddressInput | undefined;
      noticeToVacateFlow: boolean;
      reasonForMoveOut: {
        option: string;
        additionalInfoRequired?: boolean;
        additionalInfo?: string;
      };
      moveOutDate: string | undefined;
    }) => {
      const response = await updateMoveOutInfo({
        variables: {
          forwardingAddress,
          noticeToVacateFlow,
          reasonForMoveOut,
          moveOutDate,
        },
      });

      return response;
    },
    [updateMoveOutInfo],
  );

  const [resetAllRenewalOffers, { loading: resetLoading }] = useMutation(
    RESET_ALL_RENEWAL_OFFER_MUTATION,
    {
      refetchQueries: [{ query: GET_USER }, 'GetUser'],
    },
  );
  const resetOffers = useCallback(
    async ({
      pricingType,
      rewards,
    }: {
      pricingType?: PricingType;
      rewards?: boolean;
    }) => {
      await resetAllRenewalOffers({
        variables: {
          pricingType,
          rewards,
        },
      });
    },
    [resetAllRenewalOffers],
  );

  const [acceptRenewalOffer, { loading: acceptLoading }] = useMutation(
    ACCEPT_RENEWAL_OFFER_MUTATION,
    {
      refetchQueries: [{ query: GET_RENEWAL_DATA }, 'GetRenewalData'],
    },
  );

  const acceptOffer = useCallback(
    async ({
      renewalOfferPricingTermId,
      residentInfo,
      addedRentableItems,
      removedRentableItems,
      totalMonthlyChargeRange,
      removedResidents,
      addedResidents,
    }: {
      renewalOfferPricingTermId: string;
      additionalInfo?: string;
      residentInfo: ResidentInfoInput;
      addedRentableItems?: RentableItemCategoryInput[];
      removedRentableItems?: ChargeInput[];
      totalMonthlyChargeRange?: MinMaxFloatRangeInput;
      removedResidents: ResidentInput[];
      addedResidents: boolean;
    }) => {
      await acceptRenewalOffer({
        variables: {
          renewalOfferPricingTermId,
          residentInfo,
          addedRentableItems,
          removedRentableItems, // this is where it is breaking
          totalMonthlyChargeRange,
          removedResidents,
          addedResidents,
        },
      });
    },
    [acceptRenewalOffer],
  );

  return {
    declineOffer,
    declineOfferLoading,
    resetOffers,
    resetLoading,
    acceptOffer,
    acceptLoading,
    needSomethingElse,
    needSomethingElseLoading,
    moveOutInfoUpdate,
    updateMoveOutInfoLoading,
  };
}
