import useLoggedInStatus from '$/graphql/hooks/auth/useLoggedInStatus';
import initVar from '$/graphql/variables/init';
import { useReactiveVar } from '@apollo/client';

export default function useConnect() {
  const status = useLoggedInStatus();
  const init = useReactiveVar(initVar); // init is false until apollo determines logged-in state for the first time client side
  const isAuthenticated = init && status === 'logged-in';

  return {
    isAuthenticated,
  };
}
