import React from 'react';

import useConnect from './connect';
import * as Styled from './styles';
import { Props } from './types';

function ErrorView({ type }: { type: 'error' | 'maintenance' }): JSX.Element {
  const { isAuthenticated } = useConnect();

  const Wrapper = ({ children }: Props) =>
    isAuthenticated ? (
      <Styled.MainContainer>{children}</Styled.MainContainer>
    ) : (
      <Styled.GuestContainer>{children}</Styled.GuestContainer>
    );

  return (
    <Wrapper>
      <Styled.Content>
        {type === 'error' ? <Styled.ErrorImage /> : <Styled.MaintenanceImage />}

        {type === 'error' ? (
          <Styled.Title>
            No one is home! (this&nbsp;page&nbsp;couldn&apos;t&nbsp;load)
          </Styled.Title>
        ) : (
          <Styled.Title>
            Renew is currently down for maintenance. <br />
            Thanks for your patience - we&apos;ll be back soon!
          </Styled.Title>
        )}
        {type === 'error' ? (
          <Styled.Description>
            <p>Our apologies! A few tips to troubleshoot:</p>
            <Styled.List>
              <Styled.ListItem>
                <Styled.Link
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.reload();
                  }}
                >
                  Refresh this page
                </Styled.Link>
              </Styled.ListItem>
              <Styled.ListItem>
                <Styled.Link href="/">Go back to Renew home</Styled.Link>
              </Styled.ListItem>
            </Styled.List>
            <p>
              Questions or need help? Drop us a line at{' '}
              <Styled.Link
                $underlined
                href="mailto:residentsupport@heyrenew.com"
              >
                residentsupport@heyrenew.com
              </Styled.Link>
            </p>
          </Styled.Description>
        ) : (
          <Styled.Description>
            <p>
              Questions or need help? Drop us a line at{' '}
              <Styled.Link
                $underlined
                href="mailto:residentsupport@heyrenew.com"
              >
                residentsupport@heyrenew.com
              </Styled.Link>
            </p>
          </Styled.Description>
        )}
      </Styled.Content>
    </Wrapper>
  );
}

export default ErrorView;
