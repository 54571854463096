export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    DownloadableFileUrlResponse: ['DownloadableFileUrl', 'Error'],
    ESignatureEmbedUrlResponse: [
      'ESignatureEmbedPayload',
      'ESignatureEmbedResponseError',
    ],
    Housing: ['Community', 'FloorPlan', 'Unit'],
    LoginResponse: ['AuthenticationPayload', 'InvalidLoginError'],
    OnboardingUserResponse: ['OnboardingUser', 'UnknownOnboardingUser'],
    PaginationOutput: ['MatchingCommunitiesOutput'],
    RefreshAccessTokenResponse: ['AuthenticationPayload', 'InvalidLoginError'],
    RenewalOfferDecisionResponse: ['RenewalOffer', 'RenewalOfferDecisionError'],
    RentersInsuranceInfoResponse: ['Error', 'RentersInsuranceInfo'],
    SendOneTimePasswordResponse: [
      'InvalidOneTimePasswordRequestError',
      'OneTimePassword',
    ],
    SubmitCommunityReviewResponse: [
      'CommunityReview',
      'CommunityReviewDisabled',
    ],
  },
};
export default result;
