import setupClient from '$/graphql/client';
import link from '$/graphql/statusNotifier';
import { useMemo } from 'react';

export default function useConnect(showError: () => void) {
  const client = useMemo(
    () => setupClient({ statusNotifierLink: link, showError }),
    [showError],
  );

  return { client };
}
