import type { UserFragment } from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

import COMMUNITY_SUMMARY_FRAGMENT from './CommunitySummary';
import CURRENT_COMMUNITY_FRAGMENT from './CurrentCommunity';
import INCENTIVE_FRAGMENT from './Incentive';
import LEASE_FRAGMENT from './Lease';
import RENEWAL_OFFER_FRAGMENT from './RenewalOffer';
import RESIDENT_FRAGMENT from './Resident';
import UPGRADE_UNIT_FRAGMENT from './UpgradeUnit';
import USER_EXPERIMENT_FRAGMENT from './UserExperiment';
import USER_PREFERENCES_FRAGMENT from './UserPreference';

const USER_FRAGMENT: TypedDocumentNode<UserFragment> = gql`
  fragment User on User {
    id
    cellPhone
    homePhone
    claimed
    communityLogoId
    experiments {
      ...UserExperiment
    }
    currentLease {
      ...Lease
      community {
        ...CurrentCommunity
      }
      renewalOffer {
        ...RenewalOffer
      }
    }
    email
    favorites {
      active
      housing {
        ... on Community {
          ...CommunitySummary
        }
      }
    }
    firstName
    unitUpgrade {
      createdAt
      unit {
        ...UpgradeUnit
      }
    }
    hasRentersInsurance
    inviteToken
    lastName
    preferences {
      ...UserPreference
    }
    incentives {
      ...Incentive
    }
    upgradeUnitStatus
    enabledFeatures
    resident {
      ...Resident
    }
  }
  ${LEASE_FRAGMENT}
  ${INCENTIVE_FRAGMENT}
  ${UPGRADE_UNIT_FRAGMENT}
  ${USER_PREFERENCES_FRAGMENT}
  ${COMMUNITY_SUMMARY_FRAGMENT}
  ${CURRENT_COMMUNITY_FRAGMENT}
  ${RENEWAL_OFFER_FRAGMENT}
  ${USER_EXPERIMENT_FRAGMENT}
  ${RESIDENT_FRAGMENT}
`;

export default USER_FRAGMENT;
