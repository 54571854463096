import type { UserFragment } from '$/graphql/generated';
import type { WithoutNulls } from '$/utils/withoutNulls';
import { parseISO } from 'date-fns';

import normalizeCommunitySummary from './CommunitySummary';
import normalizeIncentives from './Incentive';
import normalizeLease from './Lease';
import normalizeResident from './Resident';
import { normalizeUpgradeStatus } from './UpgradeStatus';
import normalizeUpgradeUnit from './UpgradeUnit';
import normalizeUserPreference from './UserPreference';

export default function normalizeUser(input: WithoutNulls<UserFragment>) {
  return {
    id: input.id,
    cellPhone: input.cellPhone,
    homePhone: input.homePhone,
    communityLogoId: input.communityLogoId,
    currentLease: normalizeLease(input.currentLease),
    email: input.email,
    favoriteCommunities: input.favorites.flatMap(({ active, housing }) => {
      if (active && housing.__typename === 'Community') {
        return normalizeCommunitySummary(housing);
      }
      return [];
    }),
    firstName: input.firstName,
    hasRentersInsurance: input.hasRentersInsurance ? 'Yes' : 'No',
    inviteToken: input.inviteToken,
    lastName: input.lastName,
    name: `${input.firstName} ${input.lastName}`,
    preferences: normalizeUserPreference(input.preferences),
    unitUpgrade: input.unitUpgrade
      ? {
          createdAt: parseISO(input.unitUpgrade.createdAt).getTime(),
          unit: normalizeUpgradeUnit(input.unitUpgrade.unit),
        }
      : undefined,
    incentives: normalizeIncentives(input.incentives),
    upgradeStatus: normalizeUpgradeStatus(input.upgradeUnitStatus),
    experiments: input.experiments,
    enabledFeatures: input.enabledFeatures,
    resident: normalizeResident(input.resident),
  };
}
export type User = ReturnType<typeof normalizeUser>;
