import ErrorView from '$/containers/Views/Error';
import React, { useCallback, useState } from 'react';

function GraphQlErrorBoundary({
  children,
}: {
  children: (showError: () => void) => JSX.Element;
}): JSX.Element {
  const [doShowError, setDoShowError] = useState(false);
  const showError = useCallback(() => setDoShowError(true), []);
  return <>{doShowError ? <ErrorView type="error" /> : children(showError)}</>;
}

export default GraphQlErrorBoundary;
