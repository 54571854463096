// Next doesn't allow Global CSS imports from a file other
// than pages/_app.js (nextjs.org/docs/messages/css-global)
// We can revisit this for a better solution
import '$/styles/fonts.css';
import GraphQlErrorBoundary from '$/components/Atoms/GraphQlErrorBoundary';
import AnalyticsProvider from '$/containers/Services/AnalyticsProvider';
import GraphQLProvider from '$/containers/Services/GraphQLProvider';
import SentryUserManager from '$/containers/Services/SentryUserManager';
import ErrorView from '$/containers/Views/Error';
import { GlobalProvider } from '$/context/global/globalContext';
import GlobalStyle from '$/styles/global';
import themes from '$/styles/themes';
import ClientRenderOnly from '$/utils/nextjs';
import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/nextjs';
import { AppProps } from 'next/app';
import Head from 'next/head';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Toaster } from 'react-hot-toast';
import { ThemeProvider } from 'styled-components';

import MaintenanceViewPage from './maintenance';

export default function App({ Component, pageProps }: AppProps): JSX.Element {
  const getComponent = () => {
    if (process.env['NEXT_PUBLIC_MAINTENANCE_MODE'] === 'true') {
      return <MaintenanceViewPage />;
    }
    return <Component {...pageProps} />;
  };
  return (
    <GlobalProvider>
      <ThemeProvider theme={themes.light}>
        <GraphQlErrorBoundary>
          {(showError: () => void) => (
            <GraphQLProvider showError={showError}>
              <GlobalStyle />
              <Toaster />
              <AnalyticsProvider />
              <SentryUserManager />
              <ClientRenderOnly>
                <Head>
                  <title>Renew</title>
                </Head>
                <ErrorBoundary
                  fallback={<ErrorView type="error" />}
                  onError={(error) => {
                    Sentry.captureException(error);
                    datadogRum.addError(error);
                  }}
                >
                  {getComponent()}
                </ErrorBoundary>
              </ClientRenderOnly>
            </GraphQLProvider>
          )}
        </GraphQlErrorBoundary>
      </ThemeProvider>
    </GlobalProvider>
  );
}
