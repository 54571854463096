import { ApolloProvider } from '@apollo/client';
import React from 'react';

import useConnect from './connect';
import Initializer from './Initializer';
import type { Props } from './types';

export default function GraphQLProvider({
  children,
  showError,
}: Props): JSX.Element {
  const { client } = useConnect(showError);

  return (
    <ApolloProvider client={client}>
      <Initializer>{children}</Initializer>
    </ApolloProvider>
  );
}
