import type {
  SubmitNeedSoemthingElseMutation,
  SubmitNeedSoemthingElseMutationVariables,
} from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

const SUBMIT_NEED_SOMETHING_ELSE_MUTATION: TypedDocumentNode<
  SubmitNeedSoemthingElseMutation,
  SubmitNeedSoemthingElseMutationVariables
> = gql`
  mutation SubmitNeedSoemthingElse(
    $needs: [String!]!
    $additionalInfo: String!
    $preferredTermId: ID
  ) {
    submitNeedSomethingElse(
      input: {
        needs: $needs
        additionalInfo: $additionalInfo
        preferredTermId: $preferredTermId
      }
    ) {
      needs
      additionalInfo
    }
  }
`;

export default SUBMIT_NEED_SOMETHING_ELSE_MUTATION;
